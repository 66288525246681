import LockSvg from 'assets/icons/lock.svg?react';
import { Z_INDICES } from 'styles/zIndexHierarchy';
import styled from 'styled-components';

const { LOCK_ICON } = Z_INDICES;

interface LockIconProps {
  $focused: boolean;
}

export const LockIcon = styled(LockSvg)<LockIconProps>`
  height: 3rem;
  position: absolute;
  right: ${({ $focused, theme }) => ($focused ? theme.spacing[5] : theme.spacing[4])};
  top: ${({ theme }) => theme.spacing[1]};
  width: 3rem;
  z-index: ${LOCK_ICON};
`;
