import { NavigationKeyMappings } from 'utils/buttons';

enum ControllerButtons {
  /**  Remote Back */
  BACK = 461,
  /**  Enter */
  ENTER = 13,
  /**  Esc */
  ESC = 27,
  /**  Down */
  DOWN = 40,
  /**  Remote Fast Forward */
  FAST_FORWARD = 417,
  /**  Left */
  LEFT = 37,
  /** Remote Play/Pause */
  PLAY_PAUSE = 415,
  /** Remote Pause */
  PAUSE = 19,
  /**  Remote Rewind */
  REWIND = 412,
  /**  Right */
  RIGHT = 39,
  /**  Up */
  UP = 38,
}

const NavigationButtons = {
  back: [ControllerButtons.BACK, ControllerButtons.ESC],
  down: [ControllerButtons.DOWN],
  enter: [ControllerButtons.ENTER],
  left: [ControllerButtons.LEFT],
  right: [ControllerButtons.RIGHT],
  up: [ControllerButtons.UP],
};

const MediaButtons = {
  fastForward: [ControllerButtons.FAST_FORWARD],
  pause: [ControllerButtons.PAUSE],
  play: [],
  playPause: [ControllerButtons.PLAY_PAUSE],
  rewind: [ControllerButtons.REWIND],
};

const CustomButtons = {
  exit: [],
  focusNavBar: [],
  toggleVideoContentVisibility: [
    ControllerButtons.ENTER,
    ControllerButtons.DOWN,
    ControllerButtons.FAST_FORWARD,
    ControllerButtons.LEFT,
    ControllerButtons.PAUSE,
    ControllerButtons.PLAY_PAUSE,
    ControllerButtons.REWIND,
    ControllerButtons.RIGHT,
    ControllerButtons.UP,
  ],
};

export const NAVIGATION_KEY_MAP: NavigationKeyMappings = {
  ...NavigationButtons,
  ...MediaButtons,
  ...CustomButtons,
};
